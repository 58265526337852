export const PATH = {
    HOME :"/",
    CHECK_VIHCLE :"/check-vihcle",
    BOOKING: '/booking',
    BOOKING_PARTNER_IFRAME: '/booking-partner-iframe',
    RESET_PASSWORD: '/resetPassword',
    MY_BOOKING_HYSTORY: '/my-booking-history',
    BOOKING_HYSTORY: '/booking-history',
    BOOKING_DETAIL: '/booking-detail/:customerScheduleId',

}